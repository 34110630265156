<template>
  <div :class="windowClass">
    <v-container>
       <v-row>
           <v-col cols="12">
             <v-toolbar dense>
                  <v-icon left color="secondary">mdi-gamepad-circle-up</v-icon> Combinations
                  <v-spacer/>
                 <span style="width: 250px; margin-top: 25px;">
                       <v-autocomplete
                         :items="DRAWFILTER"
                         v-model="selected_draw"
                         filled
                         dense
                         solo
                         color="blue-grey lighten-2"
                         label="Select Draw"
                         item-text="date"
                         return-object
                         @change="GetDrawCombination()"
                         >
                       
                       <template v-slot:item="data">
                           {{data.item.date}} - <small color="info">{{data.item.status}}</small>
                       </template>
                   </v-autocomplete>
                   </span>
                    <v-icon right color="success" @click="GetDrawCombination()">mdi-refresh</v-icon>
                  </v-toolbar>
                 <chart-collection :rawdata="COLLECTIONS" />
   <v-toolbar>
     <v-icon color="warning" large  @click="set_betlimit(-1)">mdi-minus-box</v-icon>
     <input type="text" 
      style="width: 80px; font-size: 24px; font-weight: bold; border: 2px solid #212121; border-radius:5px"
      class="text-center"
     v-model="limitamount"/>
     <v-icon color="success" large @click="set_betlimit(1)">mdi-plus-box</v-icon>
     <v-spacer/>
           GROSS: {{$money(TOTALCOLLECTIONS)}}
    
      <v-spacer/>
           MAX PAYOUT: {{$money(MAXPAYOUT)}}
       <v-spacer/>
           NET: {{$money(NET)}}
   </v-toolbar>
           </v-col>
       </v-row>
        <v-row>
            <v-col cols="12">
                  <v-card>
                <v-toolbar>
                  <v-icon left color="secondary">mdi-gamepad-circle-up</v-icon> Game Draw
                  <v-spacer/>
                  <v-divider vertical inset class="ml-5 mr-2"/>
                         <v-btn text small color="success" @click="state='new',opengame=true"><v-icon small left color="success">mdi-plus</v-icon>  Open Draws</v-btn>
                </v-toolbar>
                <v-card-text>
                         <game-draw-table :data="Draws" @DrawEvent="dtEvent"/>
                </v-card-text>
                  </v-card>
            </v-col>
            <v-col cols="6">
                  <v-card>
                <v-toolbar>
                  <v-icon left color="secondary">mdi-format-list-checks</v-icon> Game Summaries
                  <v-spacer/>
                  <v-divider vertical inset class="ml-5 mr-2"/>
                         <v-btn text small color="success" @click="resultform = true"><v-icon small left color="success">mdi-plus</v-icon> Key In</v-btn>
                </v-toolbar>
                <v-card-text>
                  
                          <game-summary-table :data="summaries"/>
                </v-card-text>
                  </v-card>
            </v-col>
            <v-col cols="6">
                   <v-card>
                <v-toolbar>
                  <v-icon left color="secondary">mdi-format-list-checks</v-icon> Winning Tickets
                  <v-spacer/>
                  <v-divider vertical inset class="ml-5 mr-2"/>
                         <v-btn text small color="success" @click="claimform=true"><v-icon small left color="success">mdi-plus</v-icon> CLAIM</v-btn>
                </v-toolbar>
                <v-card-text>
                             <game-winning-table :data="WinningTickets"/>
                </v-card-text>
                  </v-card>
            </v-col>
        </v-row>
    </v-container>
    <game-draw-form :show="opengame" :game="game_name" :item="draw" :state="state" @DialogEvent="dfEvent"/>
    <game-result-form :show="resultform" :game="game_name" @DialogEvent="rfEvent"/>
    <game-claim-form :show="claimform"  @DialogEvent="cfEvent"/>
   </div>
 </template>
 <script>
 import { API_MIXINS } from '@/mixins/api_mixins.js'
 import {
   mapMutations
 } from 'vuex'
   export default {
     mixins: [API_MIXINS],
     data: () => ({
         state:'new',
         limitamount: 0,
         game_name: 'STL',
         claimform: false,
         resultform: false,
         opengame: false,
         draw_collection:[],
         draw:{},
         selected_draw:{},
         Draws:[],
         summaries:[],
         WinningTickets:[]
     }),
     beforeCreate: function () {
       if (!this.$session.exists()) {
         this.$router.push('/')
       }
       console.log("beforeCreate set up")
     },
     created() {
        if (this.$session.exists()) {
           this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
           this.game_name =  this.GAMENAME
           this.setLoggedIn(true) 
           this.getDraws()
           this.getSummaries()
           this.getWinningTickets()
           this.GET_GAME_SETTING(this.game_name)
         } 
     },
     computed:{
        windowClass() {
         if(this.$store.state.responsive){
           return "va-reponsive"
         } else {
           return "va-not-reponsive"
         }
       },
       GAMENAME() {
        return this.$store.state.game_setup
       },
       DRAWFILTER(){
         var draw = []
         for(var i=0;i<this.Draws.length;i++) {
           //console.log(this.Draws[i].date +" "+this.Draws[i].time)
             draw.push({id:this.Draws[i].id,  game:this.Draws[i].game, date: this.$moment(this.Draws[i].date).format("MMM-DD")  + ", " + this.Draws[i].time, status: this.Draws[i].status})
         }
         return draw
       },
       TOTALCOLLECTIONS(){
           var total  = this.draw_collection.reduce((a,o)=>a+=this.$IsNum(o.amount), 0)
           return total
       },
       MAXPAYOUT(){
           return this.$IsNum(this.GAMEWIN) * this.$IsNum(this.limitamount)
       },
        NET(){
           return this.$IsNum(this.TOTALCOLLECTIONS) - this.$IsNum(this.MAXPAYOUT)
       },
       COLLECTIONS() {
         var data = []
         console.log(data)
         for(var i=0; i<this.draw_collection.length; i++) {
           data[i] = this.draw_collection[i]
           data[i].limit = this.limitamount
         }
        
         return data
       },
       GAMEWIN() {
           if(this.Draws.length >0) {
              return this.Draws[0].winning_amount
           }
           return 0
       }
     },
     methods: {
       ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn', 'setBetlimit']),
       set_betlimit(amt) {
         this.limitamount = this.$IsNum(this.limitamount) + this.$IsNum(amt)
        // this.GetDrawCombination()
       },
       dtEvent(item){
         this.draw = item
         this.state = 'update'
         this.opengame =true
       },
       cfEvent(){
         this.claimform = false
          this.getWinningTickets()
       },
       rfEvent(data) {
          if('close' == data.action) {
               this.resultform = false
           }
           this.getSummaries()
           this.getDraws()
              this.getWinningTickets()
       },
       dfEvent(data) {
           if('close' == data.action) {
               this.opengame = false
               this.getDraws()
           }
       }, 
       getDraws(){
         console.log(this.$moment().format("hh:mm:ss"))
            this.$http.post("draw/get", {game:this.game_name, status:"per_game"}).then(response => {
             response.data.draws != null?this.Draws= response.data.draws:this.Draws = []
            console.log(this.$moment().format("hh:mm:ss"))
         }).catch(e => {
             console.log(e)
         })
       },
       GetDrawCombination() {
          console.log("selected_draw",this.selected_draw)
          this.$http.post("draw/get_draw_combination", this.selected_draw).then(response => {
              response.data.data != null?this.draw_collection= response.data.data:this.draw_collection = []
              console.log("combinations", this.draw_collection)
         }).catch(e => {
             console.log(e)
         })
       },
       getSummaries(){
           this.$http.post("draw/get_summaries", {game:this.game_name}).then(response => {
              response.data.summaries != null?this.summaries= response.data.summaries:this.summaries = []
            console.log("summaries", response.data.summaries)
        }).catch(e => {
             console.log(e)
         })
       },
       getWinningTickets(){
           this.$http.post("ticket/winning", {game:this.game_name}).then(response => {
              response.data.winning_tickets != null?this.WinningTickets= response.data.winning_tickets:this.WinningTickets = []
         }).catch(e => {
             console.log(e)
         })
       }
     },
   }
 </script>